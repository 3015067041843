export enum SyncStatuses {
  ERROR = "REFRESH_ERROR",
  PENDING = "REFRESH_IN_PROGRESS",
  SUCCESS = "REFRESHED",
  TOTAL = "TOTAL",
  REQUIRED_REFRESH = "REQUIRED_REFRESH",
  NEW = "NEW",
}

export enum SyncStatusValues {
  REQUIRED_REFRESH = "Требуется обновление",
  REFRESHED = "Обновлено",
  REFRESH_ERROR = "Ошибка обновления",
  REFRESH_IN_PROGRESS = "В процессе обновления",
  NEW = "Новый ОИК",
}






import { defineComponent } from "@vue/composition-api";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { ITKElement } from "@monorepo/inventory/src/views/TKListView/types/tkElement";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import MonitoringWidget from "./MonitoringWidget.vue";
import { mapActions } from "vuex";
import { convertStatusesToTitle } from "@monorepo/utils/src/utils/convertStatusesToTitle";
import { TK_STATUS } from "@monorepo/utils/src/types/tkStatus";

const icons = {
  [TK_STATUS.ERROR]: `${environmentVariables.BASE_URL}assets/images/tk-journal/errorIcon.svg`,
  [TK_STATUS.SUCCESS]: `${environmentVariables.BASE_URL}assets/images/tk-journal/successIcon.svg`,
  [TK_STATUS.PENDING]: `${environmentVariables.BASE_URL}assets/images/tk-journal/pendingIcon.svg`,
  [TK_STATUS.PROCESSED]: `${environmentVariables.BASE_URL}assets/images/tk-journal/successIcon.svg`,
  [TK_STATUS.TRANSFER_HDUO]: `${environmentVariables.BASE_URL}assets/images/tk-journal/successIcon.svg`,
  [TK_STATUS.SUCCESSFULLY_UPDATED]: `${environmentVariables.BASE_URL}assets/images/tk-journal/successIcon.svg`,
  [TK_STATUS.TIMEOUT_FOR_OIK_RESPONSE]: `${environmentVariables.BASE_URL}assets/images/tk-journal/pendingIcon.svg`,
  [TK_STATUS.SENT_TO_OIK]: `${environmentVariables.BASE_URL}assets/images/tk-journal/successIcon.svg`,
};

export default defineComponent({
  components: { MonitoringWidget },
  name: "TKStatusWidget",
  data() {
    return {
      items: [],
      isLoading: false,
    };
  },
  methods: {
    ...mapActions("widjets", ["getTKItems"]),
    async getList() {
      try {
        this.isLoading = true;
        const data = await this.getTKItems();
        this.items = data.map((item: ITKElement) => {
          return {
            id: uuid(),
            itemImg: (icons as Record<string, string>)[item.status] || icons[TK_STATUS.PENDING],
            title: convertStatusesToTitle(item.status as TK_STATUS),
            date: item.receivedAt ? moment(item.receivedAt).lang("ru").fromNow() : "-",
            description: item.transportContainerLogs?.pop()?.description || "",
          };
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.getList();
  },
});












import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { module as widjets } from "@monorepo/app/src/store/modules/widjets";
import { Module } from "vuex";
import { navigationItems, ISubNav } from "../../constants/navigationItems";
import Widget from "./components/Widget.vue";
import OikWidget from "./components/OikWidget.vue";
import BugReportWidget from "./components/BugReportWidget.vue";
import InformationSecurityWidget from "./components/InformationSecurityWidget.vue";
import TKStatusWidget from "./components/TKStatusWidget.vue";
import ReportWidget from "./components/ReportWidget.vue";
import InventoryWidgetInventory from "./components/InventoryWidgetInventory.vue";
import InventoryWidgetEad from "./components/InventoryWidgetEad.vue";

export default defineComponent({
  components: {
    Widget,
    OikWidget,
    InformationSecurityWidget,
    BugReportWidget,
    ReportWidget,
    InventoryWidgetInventory,
    InventoryWidgetEad,
    TKStatusWidget,
  },
  name: "HomeView",
  data() {
    return {
      navigationItems,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    navigationItemsResult(): { positionInHeadPage: number; id: string; widgets?: string[]; subNav: ISubNav[] }[] {
      return [...(this.navigationItems as { positionInHeadPage: number; id: string; widgets?: string[]; subNav: ISubNav[] }[])]
        .sort((item1, item2) => {
          return item1.positionInHeadPage - item2.positionInHeadPage;
        })
        .map((item) => {
          return {
            ...item,
            subNav: this.getResultSubNavs(item.subNav),
          };
        })
        .filter((item) => item.subNav.length);
    },
  },
  methods: {
    getResultSubNavs(subNav: ISubNav[]) {
      const authorities: { authority: string }[] = this.user?.authorities || [];
      return subNav.filter((nav) => {
        const rights = nav.rights ? (authorities || []).some((auth) => nav.rights?.includes(auth.authority as authorities)) : true;
        return rights && !nav.isHideInHeadPage;
      });
    },
  },
  setup(props, { root }) {
    useInitTableStoreModule(root, "widjets", widjets as Module<unknown, unknown>);
  },
});

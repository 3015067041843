import { elementStatuses } from "../variables/elementStatuses";
import { ITableFiltersObject } from "../store/types/tableFiltersObject";

export const getHashDeleteStatuses = (statuses: { title: string; value: string | boolean; bool: boolean }[]): Record<string, boolean> => {
  return statuses.reduce((result: Record<string, boolean>, element) => {
    result[element.value.toString()] = element.bool;
    return result;
  }, {});
};

export const convertStatus = (
  filters: ITableFiltersObject & { fieldFilters: { status?: string[] } },
  key: string,
  sourceKey = "status",
  elementStatusesDefault: { title: string; value: string | boolean; bool: boolean }[] = elementStatuses
): void => {
  if (!filters.fieldFilters[sourceKey]) {
    return;
  }

  const statuses = getHashDeleteStatuses(elementStatusesDefault);

  filters.fieldFilters[key] = ((filters.fieldFilters[sourceKey] as string[]) || []).map((item: string) => statuses[item] || false);
  delete filters.fieldFilters[sourceKey];
};

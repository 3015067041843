import { Module, Store } from "vuex";

export const mountStoreModule = (store: Store<unknown>, moduleName: string, module: Module<unknown, unknown>): void => {
  // register a new module only if doesn't exist
  if (!(store && store.state && (store.state as Record<string, unknown>)[moduleName])) {
    store.registerModule(moduleName, module);
  } else {
    // re-use the already existing module
    console.log(`reusing module: ${moduleName}`);
  }
};

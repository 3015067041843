export enum TK_STATUS {
  ERROR = "ERROR",
  SUCCESS = "ACCEPTED_HDUO",
  PENDING = "PROCESSING",
  PROCESSED = "PROCESSED",
  TRANSFER_HDUO = "TRANSFER_HDUO",
  SUCCESSFULLY_UPDATED = "SUCCESSFULLY_UPDATED",
  TIMEOUT_FOR_OIK_RESPONSE = "TIMEOUT_FOR_OIK_RESPONSE",
  OIK_ERROR = "OIK_ERROR",
  SENT_TO_OIK = "SENT_TO_OIK",
  SENT_TO_GAS_NSI = "SENT_TO_GAS_NSI",
  REJECTED_GAS_NSI = "REJECTED_GAS_NSI",
  ACCEPTED_GAS_NSI = "ACCEPTED_GAS_NSI",
}

export enum TK_DIRECTION {
  INCOME = "INCOME",
  OUTCOME = "OUTCOME",
}






import { defineComponent } from "@vue/composition-api";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { ISJElement } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/types/ISJElement";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import MonitoringWidget from "./MonitoringWidget.vue";
import { mapActions } from "vuex";
import { eventTypes } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/types/eventTypes";

export default defineComponent({
  components: { MonitoringWidget },
  name: "InformationSecurityWidget",
  data() {
    return {
      items: [],
      isLoading: false,
    };
  },
  methods: {
    ...mapActions("widjets", ["getInfSecurityItems"]),
    async getList() {
      try {
        this.isLoading = true;
        const data = await this.getInfSecurityItems();
        this.items = data.map((item: ISJElement) => {
          return {
            id: uuid(),
            itemImg: `${environmentVariables.BASE_URL}assets/images/common/monitoring-info.svg`,
            title: eventTypes.find((type) => type.value === item.eventType)?.title || "-",
            date: item.eventTime ? moment(item.eventTime).lang("ru").fromNow() : "-",
            description: item.message || "-",
          };
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.getList();
  },
});





















import { defineComponent } from "@vue/composition-api";
import moment from "moment";
import { mapActions } from "vuex";

export default defineComponent({
  name: "ReportWidget",
  computed: {
    activeWidth(): string {
      if (!this.totalEadCount) {
        return "0%";
      }
      return `${(this.receivedEadCount / this.totalEadCount) * 100}%`;
    },
  },
  data() {
    return {
      receivedEadCount: 0,
      totalEadCount: 0,
    };
  },
  methods: {
    ...mapActions("widjets", ["getEadGraphInfo"]),
    async getData() {
      const month = +moment().format("M");
      const data: { receivedEadCount: number; totalEadCount: number } = await this.getEadGraphInfo(month);

      this.receivedEadCount = data.receivedEadCount || 0;
      this.totalEadCount = data.totalEadCount || 0;
    },
  },
  created() {
    this.getData();
  },
});

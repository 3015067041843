import { mountStoreModule } from "@monorepo/utils/src/store";
import { ComponentInstance, onUnmounted } from "@vue/composition-api";
import { Module, Store } from "vuex";

const useInitTableStoreModule = (root: ComponentInstance, name: string, module: Module<unknown, unknown>): { store: Store<unknown> } => {
  const store = root.$store;
  mountStoreModule(store, name, module);

  onUnmounted(() => {
    store.unregisterModule(name);
  });

  return {
    store,
  };
};

export default useInitTableStoreModule;

import { headerCheckboxValue } from "@monorepo/utils/src/variables/headerCheckboxValue";

export interface IOikFields {
  OIK_ID: string;
  OIK_CODE: string;
  OIK_IS_DELETED: string;
  OIK_NAME: string;
  OIK_SHORT_NAME: string;
  OIK_STATUS: string;
  DATE_REFRESH_DICT: string;
  STATUS_DICT: string;
  LAST_DATE_REFRESH_DICT: string;
  OIK_ADDRESS: string;
  OIK_DESCRIPTION: string;
  OIK_CREATE_USER: string;
  OIK_CREATION_DATE: string;
  OIK_UPDATE_USER: string;
  OIK_UPDATE_DATE: string;
  OIK_INDEX: string;
  OIK_GUID_MEDO: string;
  OIK_MEDO_ADDRESS: string;
  OIK_MEDO_NAME: string;
  OIK_EMAIL: string;
  OIK_CHECKBOX: string;
  FUNDS_LIST: string;
  STATUS: string;
  OIK_ARCHIVE: string;
  OIK_FUNDS: string;
  HANDOVER_PERIOD: string;
  HANDOVER_DATE_FROM: string;
  HANDOVER_DATE_TO: string;
  HANDOVER_HISTORY: string;
  OIK_CLOUD_ARCHIVE: string;
  OIK_CLOUD_MEDO_GUID: string;
}

export const fields: IOikFields = {
  OIK_ID: "id",
  OIK_CODE: "code",
  OIK_IS_DELETED: "isDeleted",
  OIK_NAME: "name",
  OIK_FUNDS: "funds",
  FUNDS_LIST: "fundsList",
  OIK_SHORT_NAME: "shortName",
  DATE_REFRESH_DICT: "successRefreshDate",
  STATUS_DICT: "synchronizationStatus",
  LAST_DATE_REFRESH_DICT: "lastRefreshDate",
  OIK_STATUS: "status",
  OIK_ADDRESS: "address",
  OIK_DESCRIPTION: "description",
  OIK_CREATE_USER: "createUser",
  OIK_CREATION_DATE: "createDate",
  OIK_UPDATE_USER: "updateUser",
  OIK_UPDATE_DATE: "updateDate",
  OIK_INDEX: "index",
  OIK_EMAIL: "contactEmail",
  OIK_GUID_MEDO: "medoGuid",
  OIK_MEDO_ADDRESS: "medoAddress",
  OIK_MEDO_NAME: "medoName",
  OIK_CHECKBOX: headerCheckboxValue,
  STATUS: "oikStatus",
  OIK_ARCHIVE: "archives",
  HANDOVER_PERIOD: "caseTransferPeriod",
  HANDOVER_DATE_FROM: "caseTransferStartDate",
  HANDOVER_DATE_TO: "caseTransferEndDate",
  HANDOVER_HISTORY: "transferHistory",
  OIK_CLOUD_ARCHIVE: "isCloud",
  OIK_CLOUD_MEDO_GUID: "cloudMedoGuid",
};

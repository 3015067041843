export const eventTypes = [
  {
    title: "Действие пользователя",
    value: "ACTION",
  },
  {
    title: "Ошибка",
    value: "ERROR",
  },
  {
    title: "Событие",
    value: "EVENT",
  },
];

import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertAutocompleteItemToApi } from "@monorepo/utils/src/api/convertFiltersToApi";
import { convertStatus } from "@monorepo/utils/src/api/convertDeleteStatus";
import { fields } from "@monorepo/utils/src/variables/projectsData/oikView/fields";

const convertSortKey = (key: string): string => {
  switch (key) {
    case "oikStatus":
      return "synchronizationStatus";
    case "status":
      return "isDeleted";
    default:
      return key;
  }
};

export const convertFiltersCustomToApi = (
  resultFilters: ITableFiltersObject & {
    fieldFilters: {
      status?: string;
    };
  }
): void => {
  convertStatus(resultFilters, "isDeleted");
  convertAutocompleteItemToApi(resultFilters, fields.OIK_CODE, `fieldFilters.${fields.OIK_CODE}.code`);
  convertAutocompleteItemToApi(resultFilters, fields.OIK_NAME, `fieldFilters.${fields.OIK_NAME}.name`);
  convertAutocompleteItemToApi(resultFilters, fields.OIK_ID, `fieldFilters.${fields.OIK_ID}.id`);
  convertAutocompleteItemToApi(resultFilters, fields.OIK_ARCHIVE, `fieldFilters.${fields.OIK_ARCHIVE}.id`);

  //convertAutocompleteItemToApi(resultFilters, fields.ARCHIVE_ID, `fieldFilters.${fields.ARCHIVE_ID}.id`);
  //convertAutocompleteItemToApi(resultFilters, fields.ARCHIVE_SHORT_NAME, `fieldFilters.${fields.ARCHIVE_SHORT_NAME}.shortName`);
  resultFilters.sort = Object.fromEntries(Object.entries(resultFilters.sort || {}).map(([key, value]) => [convertSortKey(key), value]));
};

import { Module } from "vuex";
import { IRootState } from "@monorepo/monitoring/src/store";
import { getQuery, getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { IBugReport } from "@monorepo/monitoring/src/views/BugReportListView/types/BugReport";
import { ITKElement } from "@monorepo/inventory/src/views/TKListView/types/tkElement";
import { ISJElement } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/types/ISJElement";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { convertFiltersToApi } from "@monorepo/utils/src/api/convertFiltersToApi";
import { convertFiltersCustomToApi } from "@monorepo/catalog/src/views/OikView/utils/convertFiltersToApi";
import { convertFiltersCustomToApi as convertFiltersCustomToApiBugReport } from "@monorepo/monitoring/src/views/BugReportListView/utils/convertFiltersToApi";
import { convertFiltersCustomToApi as convertFiltersCustomToApiISJournal } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/convertFiltersToApi";

export const module: Module<Record<string, never>, IRootState> = {
  namespaced: true,
  actions: {
    async setSuccessGraphData(info, path: string) {
      try {
        const { data } = await getQuery<{ date: string; count: number }[]>(`${getFullPath(QUERY_PATH.GET_GRAPH_OIK_DATA)}/${path}`);
        return data;
      } catch (e) {
        return [];
      }
    },
    async getTKItems() {
      const filters = {
        fieldFilters: { direction: ["INCOME"], tkType: ["INVENTORY"] },
        initMessagesLength: 5,
        openedId: null,
        sort: {},
      };
      const params = convertFiltersToApi(filters, 0, convertFiltersCustomToApi);
      const { data } = await getQuery<ITKElement[]>(QUERY_PATH.GET_TK_ELEMENTS, params);
      return data;
    },
    async getInfSecurityItems() {
      const filters = { fieldFilters: {}, initMessagesLength: 5, openedId: null, sort: { eventTime: SORT_TYPE.DESC } };
      const params = convertFiltersToApi(filters, 0, convertFiltersCustomToApiISJournal);
      const { data } = await getQuery<ISJElement[]>(QUERY_PATH.GET_AUDIT_LIST, params);
      return data;
    },
    async getErrorListItems() {
      const filters = { fieldFilters: { status: ["NOT_SOLVED"] }, initMessagesLength: 5, openedId: null, sort: { createDate: SORT_TYPE.DESC } };
      const params = convertFiltersToApi(filters, 0, convertFiltersCustomToApiBugReport);
      const { data } = await getQuery<IBugReport[]>(QUERY_PATH.GET_BUG_REPORT, params);
      return data;
    },
    async getInventoryCount(info, year: string) {
      const { data } = await getQuery<{ month: string; count: string }[]>(`${QUERY_PATH.GET_CASE_YEAR_STAT}/${year}`);
      return data;
    },
    async getEadGraphInfo(info, month: number) {
      const { data } = await getQuery<{ receivedEadCount: number; totalEadCount: number }>(`${QUERY_PATH.GET_EAD_MONTH_STAT}`, { month });
      return data;
    },
    async getEadCount(info, year: string) {
      const { data } = await getQuery<{ month: string; count: string }[]>(`${QUERY_PATH.GET_EAD_YEAR_STAT}/${year}`);
      return data;
    },
  },
};


import { defineComponent } from "@vue/composition-api";
import { Line, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;

export default defineComponent({
  name: "LineChart",
  extends: Line,
  mixins: [reactiveProp],
  props: ["options"],
  mounted(this: { renderChart: (chartData: unknown, options: unknown) => void; options: unknown; chartData: unknown }) {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  },
});



















import { defineComponent } from "@vue/composition-api";
import moment from "moment";
import LineChart from "./LineChart.vue";

export default defineComponent({
  name: "InventoryWidgetGraph",
  components: { LineChart },
  props: {
    prevGraphData: {
      type: Array,
      default() {
        return [];
      },
    },
    graphData: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: () => "",
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      options: {
        legend: {
          display: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              height: 0,
              ticks: {
                beginAtZero: true,
                fontSize: 0,
                display: false, //this will remove only the label
              },
              gridLines: {
                display: false,
                drawBorder: false,
                drawOnChartArea: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                padding: 0,
                display: false, //this will remove only the label
              },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    prevMonthCount(): number {
      const items = this.graphData as { date: string; count: number }[];
      const month = moment().month();
      return month - 1 >= 0 ? items[month - 1].count : 0;
    },
    currentMonthCount(): number {
      const items = this.graphData as { date: string; count: number }[];
      const month = moment().month();
      return items[month].count;
    },
    percent(): number {
      return this.prevMonthCount ? Math.round(((this.currentMonthCount - this.prevMonthCount) / this.prevMonthCount) * 100) : 100;
    },
    datacollection(): { labels: string[]; datasets: { label: string; data: number[]; borderColor: string; backgroundColor: string }[] } {
      const items = this.graphData as { date: string; count: number }[];
      return {
        labels: (items || []).map((item) => item.date),
        datasets: [
          {
            label: "Dataset",
            data: (items || []).reduce((result: number[], item) => result.concat(item.count), []),
            borderColor: `rgb(0,164,89)`,
            backgroundColor: `rgba(0,164,89,0.2)`,
          },
        ],
      };
    },
  },
});

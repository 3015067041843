
































import { defineComponent } from "@vue/composition-api";
import { SyncStatuses } from "@monorepo/catalog/src/views/OikView/types/syncStatuses";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { mapActions } from "vuex";

// type key = "successCount" | "pendingCount" | "errorCount" | "totalCount";

type ICountItem = { count: number };

const counts = ["successCount", "pendingCount", "errorCount", "totalCount"];

const objectKey: { [key in SyncStatuses]?: string } = {
  [SyncStatuses.SUCCESS]: "successCount",
  [SyncStatuses.PENDING]: "pendingCount",
  [SyncStatuses.ERROR]: "errorCount",
  [SyncStatuses.TOTAL]: "totalCount",
};

export default defineComponent({
  name: "OikWidget",
  data() {
    return {
      syncStatuses: SyncStatuses,
      ...counts.reduce((res, item) => Object.assign(res, { [item]: 0 }), {}),
    };
  },
  methods: {
    ...mapActions("widjets", ["setSuccessGraphData"]),
    getPath(type?: string): string {
      switch (type) {
        case SyncStatuses.ERROR:
          return "error-refreshed";
        case SyncStatuses.SUCCESS:
          return "success-refreshed";
        case SyncStatuses.PENDING:
          return "wait-response";
        default:
          return "total-formed";
      }
    },
    syncIconSrc(syncStatus: string): string {
      switch (syncStatus) {
        case SyncStatuses.PENDING:
          return `${environmentVariables.BASE_URL}assets/images/oik/pendingIcon.svg`;
        case SyncStatuses.ERROR:
          return `${environmentVariables.BASE_URL}assets/images/oik/errorIconArrows.svg`;
        case SyncStatuses.SUCCESS:
          return `${environmentVariables.BASE_URL}assets/images/oik/successIcon.svg`;
        case SyncStatuses.REQUIRED_REFRESH:
          return `${environmentVariables.BASE_URL}assets/images/oik/requiredRefreshIcon.svg`;
        case SyncStatuses.NEW:
          return `${environmentVariables.BASE_URL}assets/images/oik/newIcon.svg`;
        default:
          return "";
      }
    },
    getCount(data: ICountItem[]): number {
      return (data || []).reduce((result: number, item) => result + item.count, 0);
    },
  },
  created() {
    [SyncStatuses.ERROR, SyncStatuses.PENDING, SyncStatuses.SUCCESS, SyncStatuses.TOTAL].map(async (status: SyncStatuses) => {
      const resultItems = await this.setSuccessGraphData(this.getPath(status));
      const count = this.getCount(resultItems);
      const key = objectKey[status] || objectKey[SyncStatuses.TOTAL] || "";
      (this as unknown as { [key: string]: number })[key] = count;
    });
  },
});

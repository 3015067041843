import { TK_STATUS } from "@monorepo/utils/src/types/tkStatus";

export const convertStatusesToTitle = (status: TK_STATUS): string => {
  switch (status) {
    case TK_STATUS.PENDING:
      return "В обработке";
    case TK_STATUS.TRANSFER_HDUO:
      return "Передача в ХД";
    case TK_STATUS.OIK_ERROR:
      return "Ошибка ответа от ОИК";
    case TK_STATUS.ERROR:
      return "Ошибка";
    case TK_STATUS.PROCESSED:
      return "Обновление справочников - ответ из ОИК";
    case TK_STATUS.SUCCESS:
      return "Принят в ХД";
    case TK_STATUS.SUCCESSFULLY_UPDATED:
      return "Успешно обновлен";
    case TK_STATUS.TIMEOUT_FOR_OIK_RESPONSE:
      return "Таймаут ответа в ОИК";
    case TK_STATUS.SENT_TO_OIK:
      return "Отправлено в ОИК";
    case TK_STATUS.SENT_TO_GAS_NSI:
      return "Отправлен в ГАС НСИ";
    case TK_STATUS.REJECTED_GAS_NSI:
      return "Отклонен ГАС НСИ";
    case TK_STATUS.ACCEPTED_GAS_NSI:
      return "Принят в ГАС НСИ";
    default:
      return "";
  }
};

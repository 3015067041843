




import { defineComponent } from "@vue/composition-api";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { IBugReport } from "@monorepo/monitoring/src/views/BugReportListView/types/BugReport";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import MonitoringWidget from "./MonitoringWidget.vue";
import { mapActions } from "vuex";
import { Statuses } from "@monorepo/monitoring/src/views/BugReportListView/constants/bugReportStatuses";

export default defineComponent({
  components: { MonitoringWidget },
  name: "BugReportWidget",
  data() {
    return {
      items: [],
      isLoading: false,
    };
  },
  methods: {
    ...mapActions("widjets", ["getErrorListItems"]),
    async getList() {
      try {
        this.isLoading = true;
        const data = await this.getErrorListItems();
        this.items = (data || []).map((item: IBugReport) => {
          return {
            id: uuid(),
            itemImg: `${environmentVariables.BASE_URL}assets/images/common/monitoring-info.svg`,
            title: item.status ? Statuses[item.status] || "" : "",
            date: item.createDate ? moment(item.createDate).lang("ru").fromNow() : "-",
            description: item.message || "-",
          };
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.getList();
  },
});

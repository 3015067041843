




import { defineComponent } from "@vue/composition-api";
import { mapActions } from "vuex";
import InventoryWidgetGraph from "./InventoryWidgetGraph.vue";
import moment from "moment";

const getDefaultGraphData = () =>
  new Array(12).fill(0).map((item, i) => ({ date: `${moment().format("YYYY")}-${i + 1 < 10 ? `0${i + 1}` : i + 1}-01`, count: 0 }));

export default defineComponent({
  name: "InventoryWidgetEad",
  components: { InventoryWidgetGraph },
  data() {
    return {
      prevGraphData: getDefaultGraphData(),
      graphData: getDefaultGraphData(),
    };
  },
  methods: {
    ...mapActions("widjets", ["getEadCount"]),
    getObjCountYear(arr: { month: string; count: string }[]) {
      return arr.reduce((res: Record<string, number>, item: { month: string; count: string }) => {
        return Object.assign(res, { [item.month]: +item.count });
      }, {});
    },
    async getLists() {
      const currentYear = moment().format("YYYY");
      const previousYear = moment().add(-1, "year").format("YYYY");
      const dataCurrentYear = await this.getEadCount(currentYear);
      const dataPrevYear = await this.getEadCount(previousYear);

      const dataCurrentYearObj = this.getObjCountYear(dataCurrentYear);
      const dataPrevYearObj = this.getObjCountYear(dataPrevYear);

      this.graphData = this.graphData.map((item) => {
        const month = moment(item.date, "YYYY-MM-DD").format("M");
        return {
          ...item,
          count: dataCurrentYearObj[month] ? dataCurrentYearObj[month] : 0,
        };
      });

      this.prevGraphData = this.prevGraphData.map((item) => {
        const month = moment(item.date, "YYYY-MM-DD").format("M");
        return {
          ...item,
          count: dataPrevYearObj[month] ? dataPrevYearObj[month] : 0,
        };
      });
    },
  },
  created() {
    this.getLists();
  },
});

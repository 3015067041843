






















import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";
import { ISubNav } from "../../../constants/navigationItems";

export default defineComponent({
  name: "Widget",
  props: {
    item: {
      type: Object as () => { subNav: ISubNav; icon: string; title: string; widgetAuthority: string[] },
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    subNavResult(this: { item: { subNav: ISubNav[]; icon: string; title: string } }): ISubNav[] {
      return (this.item.subNav || []).filter((item) => !item.isHideInHeadPage);
    },
    isWidgetAuthorityExist(): boolean {
      const authorities: { authority: string }[] = this.user?.authorities || [];
      const rights = this.item.widgetAuthority
        ? (authorities || []).some((auth) => this.item.widgetAuthority?.includes(auth.authority as string))
        : true;
      return rights;
    },
  },
  methods: {
    openNavigation(href: string) {
      this.$router.push(href);
    },
  },
});
